import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import {
	type SwitchProps as ReactAriaSwitchProps,
	Switch as ReactAriaSwitch,
} from 'react-aria-components'

import { cn } from '#app/utils/misc.tsx'

const switchIndicatorVariants = cva(
	[
		'cursor-pointer rounded-full bg-white p-0.5 transition-colors dark:bg-elysium',
		// Focus
		'group-focus:ring-2 group-focus:ring-dark-biege group-focus:ring-offset-2 dark:group-focus:ring-smoky dark:group-focus:ring-offset-charcoal',
		// Selected
		'group-selected:bg-white dark:group-selected:bg-elysium',
		// Disabled
		'group-disabled:cursor-not-allowed group-disabled:opacity-40',
	],
	{
		variants: {
			size: {
				lg: 'h-7 w-12',
				md: 'h-5 w-8',
				sm: 'h-4 w-6',
				theme: 'h-[33px] w-[72px]',
			},
		},
		defaultVariants: {
			size: 'md',
		},
	},
)

const switchIndicatorInnerVariants = cva(
	'rounded-full bg-white group-selected:bg-elysium transition-transform',
	{
		variants: {
			size: {
				lg: 'h-6 w-6 group-selected:translate-x-5',
				md: 'h-4 w-4 group-selected:translate-x-3',
				sm: 'h-3 w-3 group-selected:translate-x-2',
				theme:
					'h-[23px] w-[23px] translate-x-1 translate-y-0.5 border border-grey transition-transform duration-100 will-change-transform group-selected:translate-x-[40.5px] relative z-[1]',
			},
		},
		defaultVariants: {
			size: 'md',
		},
	},
)

export interface SwitchProps
	extends ReactAriaSwitchProps,
		VariantProps<typeof switchIndicatorVariants> {
	className?: string
}

export const Switch = ({ children, className, ...props }: SwitchProps) => {
	return (
		<ReactAriaSwitch
			className={cn(
				[
					'group',
					'flex items-center gap-2 font-medium text-black dark:text-white',
				],
				className,
			)}
			{...props}
		>
			{children}
		</ReactAriaSwitch>
	)
}

export interface SwitchIndicatorProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof switchIndicatorVariants> {
	className?: string
	// size?: 'lg' | 'md' | 'sm' | 'theme'
}

export const SwitchIndicator = ({
	size,
	className,
	...props
}: SwitchIndicatorProps) => {
	return (
		<div
			className={cn(switchIndicatorVariants({ size, className }))}
			{...props}
		>
			<div className={cn(switchIndicatorInnerVariants({ size, className }))} />
		</div>
	)
}
