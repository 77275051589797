import { useForm } from '@conform-to/react';
import { parse } from '@conform-to/zod';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  json,
  type LoaderFunctionArgs,
  type ActionFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction } from
'@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetcher,
  useFetchers,
  useLoaderData } from
'@remix-run/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { z } from 'zod';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { ErrorList } from './components/forms.tsx';
import { EpicProgress } from './components/progress-bar.tsx';
import { useToast } from './components/toaster.tsx';
import { Icon, href as iconsHref } from './components/ui/icon.tsx';
import { EpicToaster } from './components/ui/sonner.tsx';
import { Switch, SwitchIndicator } from './components/ui/switch.tsx';
import fontStyleSheetUrl from './styles/font.css';
import hljsStylesheetUrl from './styles/hljs.css';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { getUserId, logout } from './utils/auth.server.ts';
import { ClientHintCheck, getHints, useHints } from './utils/client-hints.tsx';
import { csrf } from './utils/csrf.server.ts';
import { prisma } from './utils/db.server.ts';
import { getEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';
import { i18n, localeCookie } from './utils/i18n.server.ts';
import { combineHeaders, getDomainUrl } from './utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { useRequestInfo } from './utils/request-info.ts';
import { aldoWiseStore } from './utils/store.tsx';
import { type Theme, setTheme, getTheme } from './utils/theme.server.ts';
import { makeTimings, time } from './utils/timing.server.ts';
import { getToast } from './utils/toast.server.ts';
import { useDirection, useLocale } from './utils/usei18n.tsx';

export const links: LinksFunction = () =>
[
// Preload svg sprite as a resource to avoid render blocking
{ rel: 'preload', href: iconsHref, as: 'image' },
// Preload CSS as a resource to avoid render blocking
{ rel: 'preload', href: fontStyleSheetUrl, as: 'style' },
{ rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
{ rel: 'preload', href: hljsStylesheetUrl, as: 'style' },
cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
{ rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
{
  rel: 'alternate icon',
  type: 'image/png',
  href: '/favicons/favicon-32x32.png'
},
{ rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' }, (
{
  rel: 'manifest',
  href: '/site.webmanifest',
  crossOrigin: 'use-credentials'
} as const), // necessary to make typescript happy
//These should match the css preloads above to avoid css as render blocking resource
{ rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
{ rel: 'stylesheet', href: fontStyleSheetUrl },
{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
{ rel: 'stylesheet', href: hljsStylesheetUrl },
cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
filter(Boolean);

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? 'Aldowise' : 'Error | Aldowise' },
  { name: 'description', content: `Your own Aldo assistant` }];

};

function useChangeLanguage(locale: string) {
  let { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale, i18n]);
}

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings('root loader');
  const locale = await i18n.getLocale(request);
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root'
  });

  const user = userId ?
  await time(
    () =>
    prisma.user.findUniqueOrThrow({
      select: {
        id: true,
        name: true,
        username: true,
        image: { select: { id: true } }
      },
      where: { id: userId }
    }),
    { timings, type: 'find user', desc: 'find user in root' }
  ) :
  null;
  if (userId && !user) {
    console.info('something weird happened');
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: '/' });
  }
  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();

  return json(
    {
      user,
      locale,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request)
        }
      },
      ENV: getEnv(),
      toast,
      honeyProps,
      csrfToken
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders,
        csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null,
        { 'set-cookie': await localeCookie.serialize(locale) }
      )
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

const ThemeFormSchema = z.object({
  theme: z.enum(['system', 'light', 'dark'])
});

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const submission = parse(formData, {
    schema: ThemeFormSchema
  });
  if (submission.intent !== 'submit') {
    return json(({ status: 'idle', submission } as const));
  }
  if (!submission.value) {
    return json(({ status: 'error', submission } as const), { status: 400 });
  }
  const { theme } = submission.value;

  const responseInit = {
    headers: { 'set-cookie': setTheme(theme) }
  };
  return json({ success: true, submission }, responseInit);
}

function Document({
  children,
  locale,
  nonce,
  theme = 'light',
  env = {}






}: {children: React.ReactNode;locale: string;nonce: string;theme?: Theme;env?: Record<string, string>;}) {
  let dir = useDirection();
  return (
    <html lang={locale} dir={dir} className={`${theme}`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<meta name="color-scheme" content="light dark" />
				<meta name="theme-color" media="all" content="#f1f1f1" />
				<meta
          name="theme-color"
          media="(prefers-color-scheme: dark)"
          content="#222222" />

				<Links />
			</head>
			<body className="relative overflow-hidden bg-white text-black dark:bg-elysium dark:text-white">
				{children}
				<script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const theme = useTheme();
  useChangeLanguage(data.locale);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const toggleSideBar = aldoWiseStore((state) => state.toggleMenu);
  useToast(data.toast);

  useEffect(() => {
    if (isMobile) {
      return toggleSideBar();
    }
  }, [isMobile, toggleSideBar]);

  return (
    <Document locale={data.locale} nonce={nonce} theme={theme} env={data.ENV}>
			<div className="relative flex h-screen flex-col justify-between overflow-hidden">
				<Outlet />
				<EpicToaster closeButton position="top-center" theme={theme} />
			</div>
			<ThemeSwitch userPreference={data.requestInfo.userPrefs.theme} />
			<EpicProgress />
		</Document>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
			<HoneypotProvider {...data.honeyProps}>
				<App />
			</HoneypotProvider>
		</AuthenticityTokenProvider>);

}

export default AppWithProviders;

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
  const hints = useHints();
  const requestInfo = useRequestInfo();
  const optimisticMode = useOptimisticThemeMode();
  if (optimisticMode) {
    return optimisticMode === 'system' ? hints.theme : optimisticMode;
  }
  return requestInfo.userPrefs.theme ?? hints.theme;
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
  const fetchers = useFetchers();
  const themeFetcher = fetchers.find((f) => f.formAction === '/');

  if (themeFetcher && themeFetcher.formData) {
    const submission = parse(themeFetcher.formData, {
      schema: ThemeFormSchema
    });
    return submission.value?.theme;
  }
}

function ThemeSwitch({ userPreference }: {userPreference?: Theme | null;}) {
  const fetcher = useFetcher<typeof action>();

  const [form] = useForm({
    id: 'theme-switch',
    lastSubmission: fetcher.data?.submission
  });

  const optimisticMode = useOptimisticThemeMode();
  const mode = optimisticMode ?? userPreference ?? 'light';
  const nextMode = mode === 'light' ? 'dark' : 'light';
  const modeLabel = {
    light:
    <Icon name="sun">
				<span className="sr-only">Light</span>
			</Icon>,

    dark:
    <Icon name="moon">
				<span className="sr-only">Dark</span>
			</Icon>

  };

  const handleThemeSwitch = () =>
  fetcher.submit({ theme: nextMode }, { method: 'POST' });

  return (
    <fetcher.Form
      method="POST"
      {...form.props}
      className="fixed right-0 top-0 z-10 flex">

			<input type="hidden" name="theme" value={nextMode} />
			<div className="flex gap-2 px-3 py-3">
				<label
          className="sr-only pr-[15px] text-[15px] leading-none text-white"
          htmlFor="airplane-mode">

					Theme
				</label>
				<Switch
          className="relative cursor-default rounded-full border border-grey outline-none group-selected:bg-elysium"
          style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}
          isSelected={mode === 'dark'}
          value={nextMode}
          onChange={handleThemeSwitch}>

					<span className="absolute left-2 top-[5.6px] h-[18px] w-[18px]">
						{modeLabel.dark}
					</span>
					<SwitchIndicator size="theme" />
					<span className="absolute right-2 top-[5.6px] h-[18px] w-[18px]">
						{modeLabel.light}
					</span>
				</Switch>
			</div>
			<ErrorList errors={form.errors} id={form.errorId} />
		</fetcher.Form>);

}

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();
  let locale = useLocale();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document locale={locale} nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>);

}